import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import translations from "../translations"

import wiresImage from "../images/wires_small.jpg"
import circuitBoardImage from "../images/circuit_board_small.jpg"

export const query = graphql`
query MemoryTestPageQuery {
  ramImage: file(relativePath: { eq: "ram.jpg" }) {
    childImageSharp {
      # Specify the image processing specifications right in the query.
      fluid(
        maxWidth: 1200
        quality: 90
      ) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

const MemoryTestPage = ({ data, pathContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title={translations[locale].memoryTestPage.header} />
    <div className="page">
      <div className="container center">
        <h2 className="page-title">{translations[locale].memoryTestPage.header}</h2>
      </div>
      <div className="container row">
        <p className="one-third">{translations[locale].memoryTestPage.firstParagraph}</p>
        <div className="two-third">
          <Img fluid={data.ramImage.childImageSharp.fluid} alt="Memory Module" />
        </div>
      </div>
      <div className="container row no-padding">
        <p>{translations[locale].memoryTestPage.secondParagraph}</p>
      </div>
      <div className="container row center">
        <p className="cite">{translations[locale].memoryTestPage.cite}</p>
      </div>
      <div className="container row no-padding">
        <p>{translations[locale].memoryTestPage.thirdParagraph}</p>
      </div>
      <div className="container row">
        <div className="half">
          <img src={wiresImage} alt="Wires" />
        </div>
        <div className="half">
          <img src={circuitBoardImage} alt="Circuit Board" />
        </div>
      </div>
    </div>
  </Layout>
)

export default MemoryTestPage
